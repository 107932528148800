import React, { useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Button, Spinner } from 'reactstrap'
import { isUndefined, sortBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import CreatePriceGroupModal from '../components/pricing/createPriceGroupModal'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import PriceGroup from '../components/pricing/priceGroup'
import { fetchStatTypesAction } from '../hasura/slices/pricing'
import { isUser } from '../lib/helpers'
import { usersSelector, UsersState } from '../hasura/slices/users'

import {
  OrganizationsState,
  fetchPriceGroupAction,
  fetchPricesGroupsAction,
  organizationsSelector,
} from '../hasura/slices/organizations'

export default function Pricing() {
  const dispatch = useDispatch()

  const [isCreatingPriceGroup, setIsCreatingPriceGroup] = React.useState(false)

  const { accessToken, role, user }: UsersState = useSelector(usersSelector)
  const { priceGroups }: OrganizationsState = useSelector(organizationsSelector)

  const isReadOnly = isUser(role)

  useEffect(() => {
    if (!accessToken || !user) return

    dispatch(fetchStatTypesAction(accessToken))

    if (isUser(role)) {
      dispatch(fetchPriceGroupAction(accessToken, user.organization.price_group_id))
    } else {
      dispatch(fetchPricesGroupsAction(accessToken, user.organization.enterprise.id))
    }
  }, [accessToken, user, role])

  return (
    <Layout>
      <MainBox defaultPadding>
        <div className="d-flex align-items-start justify-content-between">
          {isReadOnly ? (
            <div>
              <h4 className="bold">{user?.organization.display_name}'s Pricing Sheet</h4>
              <p className="text-s text--gray7">Pricing breakdown based on modality, turnaround time, and number/region of images.</p>
            </div>
          ) : (
            <h4 className="bold">Pricing</h4>
          )}

          {!isReadOnly && (
            <Button onClick={() => setIsCreatingPriceGroup(true)} color="primary">
              Create Price Group
            </Button>
          )}
        </div>

        {isCreatingPriceGroup && <CreatePriceGroupModal close={() => setIsCreatingPriceGroup(false)} />}

        {isUndefined(priceGroups) ? (
          <Spinner color="primary" />
        ) : isEmpty(priceGroups) ? (
          <p className="mt-2 mb-0 text-m">
            Click <b>Create Price Group</b> to setup your pricing.
          </p>
        ) : null}

        {sortBy(priceGroups || [], (p) => -(p.organizations_aggregate.aggregate?.count || 0)).map((group) => (
          <PriceGroup isReadOnly={isReadOnly} key={group.id} group={group} />
        ))}
      </MainBox>
    </Layout>
  )
}
