import { useDispatch, useSelector } from 'react-redux'
import React, { useState } from 'react'
import isString from 'lodash/isString'
import { Button, Input } from 'reactstrap'

import AddonPricingModal from './addonPricingModal'
import CONFIG from '../../config'
import FastTrackStatInput from '../common/FastTrackStatInput'
import SimpleStatInput from '../common/SimpleStatInput'
import SoftwareServicesTable from '../pricing/softwareServicesTable'
import { ConsultationType, getConsultationTypeData, Modality } from '../../lib/modalityHelpers'
import { NotificationId, setNotificationAction } from '../../hasura/slices/notifications'
import { PriceGroups_price_groups, PriceGroups_price_groups_prices } from '../../hasura/graphQlQueries/types/PriceGroups'
import { WEEKEND_STAT_ADDON_ID } from '../../lib/pricingHelpers'
import { softwareChargeTypeForId } from '../../lib/softwareChargeTypes'
import { usersSelector, UsersState } from '../../hasura/slices/users'

import {
  addModalityToPriceGroupAction,
  deletePricesAction,
  insertPricesAction,
  pricingSelector,
  PricingState,
  updatePriceAmountAction,
} from '../../hasura/slices/pricing'

// @ts-ignore
import starPinkIcon from '../../lib/images/star-pink.svg'
// @ts-ignore
import starGrayStrokeIcon from '../../lib/images/star-gray-stroke.svg'

interface Props {
  group: PriceGroups_price_groups
  isDefaultPriceGroup: boolean
  setDefaultPriceGroup: () => void
  ultrasoundPrices: PriceGroups_price_groups_prices[]
  catscanPrices: PriceGroups_price_groups_prices[]
  mriPrices: PriceGroups_price_groups_prices[]
}

export const getPricesForConsultationType = (group: PriceGroups_price_groups, consultationType: ConsultationType) =>
  group.prices.filter((p) => p.consultation_type_id === getConsultationTypeData(consultationType)?.consultationTypeId)

export const getBasePrice = (prices: PriceGroups_price_groups_prices[]) =>
  prices.find((p) => !p.addon_type && !p.addon && !p.stat_type && !p.is_fasttrack_stat)

export default function AddonSettings(props: Props) {
  const dispatch = useDispatch()

  const { group, isDefaultPriceGroup, ultrasoundPrices, catscanPrices, mriPrices } = props

  const { accessToken, user, isRadimalEnterprise }: UsersState = useSelector(usersSelector)
  const { statTypes }: PricingState = useSelector(pricingSelector)

  const radiographConsultationTypeId = getConsultationTypeData(ConsultationType.Xray)?.consultationTypeId

  const [addonPricingModalConsultationType, setAddonPricingModalConsultationType] = useState<ConsultationType | undefined>()
  const [newWeekendStatPrice, setNewWeekendStatPrice] = useState<string | undefined>()

  const simpleStatPrice = group.prices.find((p) => p.stat_type?.display_name === 'simple')
  const fasttrackPrice = group.prices.find((p) => p.is_fasttrack_stat)
  const baseRadiographPriceAmount = getBasePrice(getPricesForConsultationType(group, ConsultationType.Xray))?.amount
  const softwarePrices = group.prices
    .filter((p) => p.software_charge_type_id)
    .map(({ amount, software_charge_type_id }) => ({ amount, description: softwareChargeTypeForId(software_charge_type_id!) }))
  const missingConsultationTypes = [
    ConsultationType.Xray,
    ConsultationType.Catscan,
    ConsultationType.Ultrasound,
    ConsultationType.MRI,
  ].filter((c) => getPricesForConsultationType(group, c).length === 0)
  const statTypesForGroup = statTypes.filter((s) => group.prices.map((p) => p.stat_type?.id).includes(s.id))
  const weekendStatPrice = group.prices.find((p) => p.addon?.display_name === 'Weekend STAT')

  /*
    Methods
  */

  const addModality = (modality: Modality) =>
    dispatch(addModalityToPriceGroupAction(accessToken, user!, group.id, modality, statTypesForGroup))

  const saveNewWeekendStatPrice = async () => {
    const parsedAmount = parseFloat(newWeekendStatPrice || '')
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      dispatch(setNotificationAction(NotificationId.InvalidAmount))
      return
    }

    if (weekendStatPrice) {
      dispatch(updatePriceAmountAction(accessToken, user!, weekendStatPrice.id, parsedAmount))
    } else {
      const price = { price_group_id: props.group.id, amount: parsedAmount, addon_id: WEEKEND_STAT_ADDON_ID }
      await dispatch(insertPricesAction(accessToken, user!, [price]))
      dispatch(setNotificationAction(NotificationId.PriceSaved))
    }
  }

  return (
    <div>
      {addonPricingModalConsultationType && (
        <AddonPricingModal
          close={() => setAddonPricingModalConsultationType(undefined)}
          consultationType={addonPricingModalConsultationType}
          priceGroup={group}
        />
      )}

      {missingConsultationTypes.length > 0 && (
        <div className="mt-4">
          <p className="bold text--gray7 text-m mb-1">Add new modalities</p>

          <div className="d-flex gap-10px">
            {missingConsultationTypes.map((consultationType) => (
              <Button
                className="width-150px"
                key={consultationType}
                onClick={() => addModality(getConsultationTypeData(consultationType)?.modality!)}
                size="sm"
              >
                Add {consultationType}
              </Button>
            ))}
          </div>
        </div>
      )}
      <div className="mt-4">
        <p className="bold text--gray7 text-m mb-1">Addon pricing</p>

        <div className="d-flex mt-2 gap-80px">
          <div>
            <div>
              <p className="mb-1 semibold text-s">Update extra image pricing for radiographs</p>
              <Button
                className="d-block width-fit-content"
                onClick={() => setAddonPricingModalConsultationType(ConsultationType.Xray)}
                size="sm"
              >
                Update
              </Button>
            </div>

            {ultrasoundPrices.length > 0 && (
              <div className="mt-2">
                <p className="mb-1 semibold text-s">Update extra region pricing for ultrasound</p>
                <Button
                  className="d-block width-fit-content"
                  onClick={() => setAddonPricingModalConsultationType(ConsultationType.Ultrasound)}
                  size="sm"
                >
                  Update
                </Button>
              </div>
            )}

            {catscanPrices.length > 0 && (
              <div className="mt-2">
                <p className="mb-1 semibold text-s">Update extra region pricing for CT</p>
                <Button
                  className="d-block width-fit-content"
                  onClick={() => setAddonPricingModalConsultationType(ConsultationType.Catscan)}
                  size="sm"
                >
                  Update
                </Button>
              </div>
            )}

            {mriPrices.length > 0 && (
              <div className="mt-2">
                <p className="mb-1 semibold text-s">Update extra region pricing for MRI</p>
                <Button
                  className="d-block width-fit-content"
                  onClick={() => setAddonPricingModalConsultationType(ConsultationType.MRI)}
                  size="sm"
                >
                  Update
                </Button>
              </div>
            )}
          </div>

          <div className="gap-10px d-flex flex-column">
            <div>
              <p className="mb-1 semibold text-s">Set a Weekend STAT price</p>
              <div className="flex-center width-fit-content">
                $
                <Input
                  className="ml-1 width-80px"
                  value={isString(newWeekendStatPrice) ? newWeekendStatPrice : weekendStatPrice?.amount || ''}
                  onChange={(e: any) => setNewWeekendStatPrice(e.target.value)}
                />
                <Button
                  className="ml-3 width-fit-content"
                  onClick={saveNewWeekendStatPrice}
                  disabled={!newWeekendStatPrice}
                  size="sm"
                  color="secondary"
                >
                  Save
                </Button>
              </div>
            </div>

            {isRadimalEnterprise && (
              <FastTrackStatInput
                amount={baseRadiographPriceAmount + (fasttrackPrice?.amount || 0)}
                displayEnable={!fasttrackPrice}
                page="price group"
                delete={() => dispatch(deletePricesAction(accessToken, user!, [fasttrackPrice!.id]))}
                enable={() => {
                  const stat_type_id = statTypes.find((s) => s.hours === 2)?.id
                  if (!stat_type_id) {
                    dispatch(setNotificationAction(NotificationId.SomethingBadHappened))
                    return
                  }

                  const price = {
                    price_group_id: group.id,
                    amount: 0,
                    is_fasttrack_stat: true,
                    stat_type_id,
                    consultation_type_id: radiographConsultationTypeId,
                  }
                  dispatch(insertPricesAction(accessToken, user!, [price]))
                }}
                update={(amount: number) =>
                  dispatch(updatePriceAmountAction(accessToken, user!, fasttrackPrice!.id, amount - baseRadiographPriceAmount))
                }
              />
            )}

            <SimpleStatInput
              amount={baseRadiographPriceAmount + simpleStatPrice?.amount}
              displayEnable={!simpleStatPrice}
              page="price group"
              delete={() => dispatch(deletePricesAction(accessToken, user!, [simpleStatPrice!.id]))}
              enable={() => {
                const price = {
                  price_group_id: group.id,
                  amount: 0,
                  stat_type_id: CONFIG.SIMPLE_STAT_ID,
                  consultation_type_id: radiographConsultationTypeId,
                }
                dispatch(insertPricesAction(accessToken, user!, [price]))
              }}
              update={(amount: number) =>
                dispatch(updatePriceAmountAction(accessToken, user!, simpleStatPrice!.id, amount - baseRadiographPriceAmount))
              }
            />
          </div>
        </div>
      </div>

      <SoftwareServicesTable softwarePrices={softwarePrices} />

      <div className="d-flex align-items-end justify-content-end gap-10px">
        {isDefaultPriceGroup && (
          <p className="text-xs m-0 text--gray5">
            This is your default price group. New practices will automatically be assigned to this group.
          </p>
        )}
        <img
          onClick={props.setDefaultPriceGroup}
          className="icon-s pointer"
          src={isDefaultPriceGroup ? starPinkIcon : starGrayStrokeIcon}
        />
      </div>
    </div>
  )
}
