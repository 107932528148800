import React, { useEffect } from 'react'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Cell, Header, HeaderCell, Row } from '../common/Table'
import { ConsultationType, Modality, getConsultationTypeData, getConsultationTypeForModality } from '../../lib/modalityHelpers'
import { PriceGroups_price_groups, PriceGroups_price_groups_prices } from '../../hasura/graphQlQueries/types/PriceGroups'
import { QueryName } from '../../hasura/queryNames'
import { UsersState, usersSelector } from '../../hasura/slices/users'
import { findAddon, thresholdDescription, validateThresholds } from '../../utils/addons'
import { getExtraImagePrices } from '../../lib/pricingHelpers'

import {
  PricingState,
  deletePricesAction,
  fetchAddonsAction,
  insertPricesAction,
  pricingSelector,
  updatePriceAmountAction,
} from '../../hasura/slices/pricing'

// @ts-ignore
import deleteIcon from '../../lib/images/delete.png'
// @ts-ignore
import closeIcon from '../../lib/images/close.png'

interface Props {
  close: () => void
  priceGroup: PriceGroups_price_groups
  consultationType: ConsultationType
}

interface EditedPriceAmount {
  priceId: number
  amount: string
}

export default function AddonPricingModal(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { addons, isQuerying }: PricingState = useSelector(pricingSelector)

  const [newThreshold, setNewThreshold] = React.useState<string | undefined>()
  const [newCineloopThreshold, setNewCineloopThreshold] = React.useState<string | undefined>()
  const [newPrice, setNewPrice] = React.useState<string | undefined>()
  const [editedPriceAmount, setEditedPriceAmount] = React.useState<EditedPriceAmount | undefined>()

  const modality = getConsultationTypeData(props.consultationType)?.modality!
  const prices = getExtraImagePrices(props.priceGroup.prices, false, modality) as PriceGroups_price_groups_prices[]
  const isCT = modality === Modality.Catscan
  const isUS = modality === Modality.Ultrasound
  const isMRI = modality === Modality.MRI
  // catscan and mri addon pricing has non-adjustable thresholds (1 region, 2 regions, and whole body)
  const canInsertAndDelete = !isCT && !isMRI

  const parsedNewThreshold = newThreshold ? parseInt(newThreshold) : undefined
  const parsedNewCineloopThreshold = newCineloopThreshold ? parseInt(newCineloopThreshold) : undefined
  const parsedNewPrice = newPrice ? parseFloat(newPrice) : undefined
  const parsedEditedPriceAmount = editedPriceAmount ? parseFloat(editedPriceAmount.amount) : undefined
  const addon = findAddon(modality, addons, parsedNewThreshold, parsedNewCineloopThreshold)

  /*
    Effects
  */

  useEffect(() => {
    dispatch(fetchAddonsAction(accessToken))
  }, [])

  /*
    Methods
  */

  const deletePrice = (price: PriceGroups_price_groups_prices) => dispatch(deletePricesAction(accessToken, user!, [price.id]))

  const errorMessage = (() => {
    if (newThreshold) {
      const error = validateThresholds(modality, prices, parsedNewThreshold, parsedNewCineloopThreshold)
      if (error) return error
    }

    if (newPrice) {
      if (!isNumber(parsedNewPrice) || parsedNewPrice <= 0) {
        return 'Price must be greater than $0.'
      }
    }

    if (editedPriceAmount) {
      if (!isNumber(parsedEditedPriceAmount) || parsedEditedPriceAmount < 0) {
        return 'Price must be greater than $0.'
      }
    }
  })()

  const createPrice = async () => {
    if (parsedNewPrice) {
      const price = {
        price_group_id: props.priceGroup.id,
        consultation_type_id: getConsultationTypeForModality(modality)?.consultationTypeId,
        amount: parsedNewPrice,
        addon_id: addon?.id,
      }
      await dispatch(insertPricesAction(accessToken, user!, [price]))
    }

    if (isNumber(parsedEditedPriceAmount)) {
      await dispatch(updatePriceAmountAction(accessToken, user!, editedPriceAmount!.priceId, parsedEditedPriceAmount))
    }

    setEditedPriceAmount(undefined)
    setNewThreshold(undefined)
    setNewCineloopThreshold(undefined)
    setNewPrice(undefined)
  }

  return (
    <Modal fade={false} centered isOpen toggle={props.close}>
      <ModalHeader className="text-center flex-center w-100">
        Update {props.consultationType} Addon Pricing
        <img
          onClick={() => dispatch(props.close)}
          className="pointer position-absolute icon-m m-1"
          src={closeIcon}
          style={{ top: '10px', right: '10px' }}
        />
      </ModalHeader>

      <ModalBody className="text-center text-m p-4">
        <table style={{ tableLayout: 'fixed' }} className="text-m mx-auto">
          <tbody>
            <Row className="bold">
              <HeaderCell className="text-center">
                <Header>{isUS ? 'Image Threshold' : 'Threshold'}</Header>
              </HeaderCell>
              {isUS && (
                <HeaderCell className="text-center">
                  <Header>Cineloop Threshold</Header>
                </HeaderCell>
              )}
              <HeaderCell className="text-center">
                <Header>Price</Header>
              </HeaderCell>
              {canInsertAndDelete && <HeaderCell />}
            </Row>

            {prices.map((p, idx) => {
              const isEditing = editedPriceAmount?.priceId === p.id
              return (
                <Row key={idx}>
                  <Cell>
                    {thresholdDescription(
                      props.consultationType,
                      p.addon?.additional_data[isUS ? 'image_threshold' : 'threshold'],
                      'image'
                    )}
                  </Cell>
                  {isUS && (
                    <Cell>
                      {thresholdDescription(props.consultationType, p.addon?.additional_data['video_threshold'], 'cineloop')}
                    </Cell>
                  )}
                  <Cell className="flex-center">
                    $
                    <Input
                      className="width-80px ml-2"
                      onChange={(e: any) => setEditedPriceAmount({ priceId: p.id, amount: e.target.value })}
                      value={isEditing ? editedPriceAmount?.amount : p.amount}
                      disabled={Boolean(!isEditing && editedPriceAmount)}
                      placeholder="Threshold"
                    />
                  </Cell>
                  {canInsertAndDelete && (
                    <Cell>
                      <img onClick={() => deletePrice(p)} className="icon-xs pointer" src={deleteIcon} />
                    </Cell>
                  )}
                </Row>
              )
            })}

            {canInsertAndDelete && (
              <Row>
                <Cell>
                  <Input
                    className="width-100px mx-auto"
                    onChange={(e: any) => setNewThreshold(e.target.value)}
                    value={newThreshold || ''}
                    placeholder={isUS ? 'Image Threshold' : 'Threshold'}
                  />
                </Cell>
                {isUS && (
                  <Cell>
                    <Input
                      className="width-100px mx-auto"
                      onChange={(e: any) => setNewCineloopThreshold(e.target.value)}
                      value={newCineloopThreshold || ''}
                      placeholder="Cineloop Threshold"
                    />
                  </Cell>
                )}
                <Cell>
                  <div className="flex-center">
                    $
                    <Input
                      className="ml-1 width-80px"
                      onChange={(e: any) => setNewPrice(e.target.value)}
                      value={newPrice || ''}
                      placeholder="Price"
                    />
                  </div>
                </Cell>
                <Cell></Cell>
              </Row>
            )}
          </tbody>
        </table>

        <p
          style={{
            opacity: ((newPrice && newThreshold && (newCineloopThreshold || !isUS)) || editedPriceAmount) && errorMessage ? 1 : 0,
          }}
          className="text--danger text-s bold mt-2 height-30px transition-f"
        >
          {errorMessage}
        </p>

        <Button
          onClick={createPrice}
          disabled={
            isQuerying[QueryName.InsertPrice] ||
            isString(errorMessage) ||
            (!editedPriceAmount && (!newPrice || !newThreshold || !addon || (isUS && !newCineloopThreshold)))
          }
          color="primary"
          className="width-200px mt-2"
        >
          Save
        </Button>
      </ModalBody>
    </Modal>
  )
}
