import React, { useState } from 'react'
import isNumber from 'lodash/isNumber'
import orderBy from 'lodash/orderBy'
import { Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import AddonSettings, { getBasePrice, getPricesForConsultationType } from './addonSettings'
import PriceGroupMenu from './menu'
import { ConsultationType, CtOrMriRegions, Modality } from '../../lib/modalityHelpers'
import { PriceGroups_price_groups, PriceGroups_price_groups_prices } from '../../hasura/graphQlQueries/types/PriceGroups'
import { Table, Header, HeaderCell, MidNarrowCell, Row } from '../common/Table'
import { blurCurrentInput, pluralize } from '../../lib/helpers'
import { deletePriceGroupAction, updateDefaultPriceGroupAction, updatePriceAmountAction } from '../../hasura/slices/pricing'
import { usersSelector, UsersState } from '../../hasura/slices/users'

import {
  getExtraImagePrices,
  thresholdsForAddOnTypes,
  getStatTypeDescription,
  getStatTypeDescriptions,
  getStatPrice,
} from '../../lib/pricingHelpers'

const ultrasoundAddonDescription = (addOnPrice: PriceGroups_price_groups_prices) => {
  const data = addOnPrice?.addon?.additional_data
  return `${data['image_threshold']}+ images / ${data['video_threshold']}+ cineloops`
}

interface Props {
  group: PriceGroups_price_groups
  isReadOnly: boolean
}

interface NewAmount {
  id: number
  value: string
  parsedValue: number
  valid: boolean
  isBase: boolean
  basePriceAmount: number
}

export default function PriceGroup(props: Props) {
  const dispatch = useDispatch()

  const [newAmount, setNewAmount] = useState<NewAmount | undefined>()

  const { group, isReadOnly } = props

  const { accessToken, defaultTurnaroundTime, user }: UsersState = useSelector(usersSelector)

  const isDefaultPriceGroup = user?.organization.enterprise.default_price_group_id === group.id

  const xrayPrices = getPricesForConsultationType(group, ConsultationType.Xray)
  const ultrasoundPrices = getPricesForConsultationType(group, ConsultationType.Ultrasound)
  const catscanPrices = getPricesForConsultationType(group, ConsultationType.Catscan)
  const internalMedicinePrices = getPricesForConsultationType(group, ConsultationType.InternalMedicine)
  const mriPrices = getPricesForConsultationType(group, ConsultationType.MRI)

  const extraImageXrayPrices = getExtraImagePrices(xrayPrices, false, Modality.Xray)
  const extraImageUltrasoundPrices = getExtraImagePrices(
    ultrasoundPrices,
    false,
    Modality.Ultrasound
  ) as PriceGroups_price_groups_prices[]

  const extraImageXrayThresholdDescriptions = thresholdsForAddOnTypes([
    null,
    ...extraImageXrayPrices.map((p) => p.addon?.additional_data['threshold']),
  ])

  const catscanAddOnTypes: (number | null)[] = [null].concat(
    // @ts-ignore
    ...orderBy(
      catscanPrices.filter((p) => p.addon?.display_name === 'Extra Regions'),
      [(p) => p.addon?.additional_data['threshold'] || 0],
      ['asc']
    ).map((p) => p.addon?.id)
  )

  let statTypeDescriptions = getStatTypeDescriptions(group.prices, defaultTurnaroundTime)
  if (statTypeDescriptions.length === 0) statTypeDescriptions = [getStatTypeDescription(defaultTurnaroundTime, null)]

  /*
    Methods
  */

  const saveNewAmount = async () => {
    if (newAmount?.parsedValue && newAmount?.valid) {
      const amount = newAmount.isBase ? newAmount.parsedValue : newAmount.parsedValue - newAmount.basePriceAmount
      await dispatch(updatePriceAmountAction(accessToken, user!, newAmount.id, amount))
      setNewAmount(undefined)
    }
    blurCurrentInput()
    setNewAmount(undefined)
  }

  const deletePriceGroup = async () => {
    if ((group.organizations_aggregate.aggregate?.count || 0) > 0) {
      alert('You cannot delete a price group that is associated with multiple practices')
      return
    }

    await dispatch(deletePriceGroupAction(accessToken, user!, group.id))
  }

  const setDefaultPriceGroup = () => dispatch(updateDefaultPriceGroupAction(accessToken, user!, group.id))

  const editablePriceCellParamsFor = (
    consultationType: ConsultationType,
    statTypeDescription: string | null,
    addOnPriceAmount?: any
  ) => {
    const prices = getPricesForConsultationType(group, consultationType)
    const basePrice = getBasePrice(prices)
    const basePriceAmount = basePrice?.amount || 0
    const statPrice = getStatPrice(statTypeDescription, defaultTurnaroundTime, prices)
    const statPriceAmount = statPrice?.amount || 0
    const isBase = !statPrice && !addOnPriceAmount
    const amount = isBase ? basePriceAmount : basePriceAmount + (addOnPriceAmount || 0) + statPriceAmount
    const priceId = isBase ? basePrice?.id : statPrice?.id
    const isEditing = newAmount?.id === priceId && !addOnPriceAmount
    return {
      consultationType,
      amount,
      basePriceAmount,
      statPriceAmount,
      addOnPriceAmount,
      priceId: priceId!,
      isBase,
      isEditing,
      statTypeDescription,
    }
  }

  const editablePriceCell = (params: {
    consultationType: ConsultationType
    priceId: number
    amount: number
    isBase: boolean
    basePriceAmount: number
    addOnPriceAmount: number
    statPriceAmount: number
    isEditing: boolean
    statTypeDescription: string | null
  }) => {
    let amountMsg = ``
    if (params.statPriceAmount) {
      amountMsg += `+ $${params.statPriceAmount} (STAT)`
    }
    if (params.addOnPriceAmount) {
      amountMsg += ` + $${params.addOnPriceAmount} (${
        params.consultationType === ConsultationType.Catscan || params.consultationType === ConsultationType.MRI ? 'regions' : 'images'
      })`
    }
    return (
      <MidNarrowCell key={`${params.priceId}-${params.statTypeDescription}`}>
        <div className="d-flex align-items-center position-relative py-1">
          $
          <Input
            className="ml-1 width-80px"
            id={`price-${params.priceId}`}
            onBlur={saveNewAmount}
            disabled={isNumber(params.addOnPriceAmount) || isReadOnly}
            onKeyDown={(e: any) => e.key === 'Enter' && saveNewAmount()}
            value={params.isEditing ? newAmount?.value : params.amount}
            onChange={(e: any) =>
              setNewAmount({
                id: params.priceId!,
                value: e.target.value,
                parsedValue: parseFloat(e.target.value),
                valid: !isNaN(parseFloat(e.target.value)),
                isBase: params.isBase,
                basePriceAmount: params.basePriceAmount,
              })
            }
            onFocus={() =>
              setNewAmount({
                id: params.priceId!,
                value: '',
                parsedValue: 0,
                valid: true,
                isBase: params.isBase,
                basePriceAmount: params.basePriceAmount,
              })
            }
          />
          {!params.isEditing && !params.isBase && !isReadOnly && (
            <p
              style={{ top: '42px', left: '14px', lineHeight: '14px' }}
              className="text--gray4 text-xxs position-absolute single-line"
            >
              {amountMsg}
            </p>
          )}
          {params.isEditing && !newAmount?.valid && (
            <p style={{ top: '43px', left: '14px', lineHeight: '14px' }} className="text-danger text-xxs bold position-absolute">
              Invalid amount
            </p>
          )}
        </div>
      </MidNarrowCell>
    )
  }

  return (
    <div key={group.id} className={`my-4 p-4 border rounded ${isDefaultPriceGroup && !isReadOnly ? 'border-primary' : ''}`}>
      {!isReadOnly && (
        <div className="d-flex justify-content-between">
          <p className="text-m text--gray7 m-0">
            <span className="bold">PRICE GROUP {group.id}</span> |{' '}
            {pluralize('practice', group.organizations_aggregate.aggregate?.count || 0)}
          </p>

          <PriceGroupMenu deletePriceGroup={deletePriceGroup} />
        </div>
      )}

      <Table>
        <thead>
          <tr>
            <HeaderCell>
              <Header>Type</Header>
            </HeaderCell>

            <HeaderCell />

            {statTypeDescriptions.map((type, idx) => (
              <HeaderCell key={idx}>
                <Header>{type}</Header>
              </HeaderCell>
            ))}
          </tr>
        </thead>

        <tbody>
          {[null, ...extraImageXrayPrices].map((addOnPrice, idx) => {
            const addOnPriceAmount = addOnPrice?.amount
            const addOnDescription = extraImageXrayThresholdDescriptions[idx]

            return (
              <Row key={idx}>
                <MidNarrowCell>{idx === 0 ? 'Radiographs' : ''}</MidNarrowCell>
                <MidNarrowCell>{addOnDescription}</MidNarrowCell>

                {statTypeDescriptions.map((statType) => {
                  return editablePriceCell(editablePriceCellParamsFor(ConsultationType.Xray, statType, addOnPriceAmount))
                })}
              </Row>
            )
          })}

          {ultrasoundPrices.length > 0 &&
            [null, ...extraImageUltrasoundPrices].map((addOnPrice, idx) => {
              const addOnPriceAmount = addOnPrice?.amount

              return (
                <Row key={idx}>
                  <MidNarrowCell>{idx === 0 ? 'Ultrasound' : ''}</MidNarrowCell>
                  <MidNarrowCell>{addOnPrice?.amount ? ultrasoundAddonDescription(addOnPrice) : ''}</MidNarrowCell>

                  {statTypeDescriptions.map((statType) => {
                    return editablePriceCell(editablePriceCellParamsFor(ConsultationType.Ultrasound, statType, addOnPriceAmount))
                  })}
                </Row>
              )
            })}

          {catscanPrices.length > 0 &&
            catscanAddOnTypes.map((addOnType, idx) => {
              const addOnPrice = catscanPrices.find((p) => p.addon?.id === addOnType)
              const addOnPriceAmount = addOnPrice?.amount
              const addOnThreshold = addOnPrice?.addon?.additional_data['threshold'] || 0
              const addOnTypeDescription =
                addOnThreshold == 2
                  ? CtOrMriRegions.WholeBody
                  : addOnThreshold == 1
                  ? CtOrMriRegions.TwoRegions
                  : CtOrMriRegions.OneRegion

              return (
                <Row key={idx}>
                  <MidNarrowCell>{idx === 0 ? 'CT' : ''}</MidNarrowCell>
                  <MidNarrowCell>{addOnTypeDescription}</MidNarrowCell>

                  {statTypeDescriptions.map((statType) => {
                    return editablePriceCell(editablePriceCellParamsFor(ConsultationType.Catscan, statType, addOnPriceAmount))
                  })}
                </Row>
              )
            })}

          {mriPrices.length > 0 &&
            catscanAddOnTypes.map((addOnType, idx) => {
              const addOnPrice = mriPrices.find((p) => p.addon?.id === addOnType)
              const addOnPriceAmount = addOnPrice?.amount
              const addOnThreshold = addOnPrice?.addon?.additional_data['threshold'] || 0
              const addOnTypeDescription =
                addOnThreshold == 2
                  ? CtOrMriRegions.WholeBody
                  : addOnThreshold == 1
                  ? CtOrMriRegions.TwoRegions
                  : CtOrMriRegions.OneRegion

              return (
                <Row key={idx}>
                  <MidNarrowCell>{idx === 0 ? 'MRI' : ''}</MidNarrowCell>
                  <MidNarrowCell>{addOnTypeDescription}</MidNarrowCell>

                  {statTypeDescriptions.map((statType) => {
                    return editablePriceCell(editablePriceCellParamsFor(ConsultationType.MRI, statType, addOnPriceAmount))
                  })}
                </Row>
              )
            })}

          {internalMedicinePrices.length > 0 && (
            <Row>
              <MidNarrowCell>Internal Medicine</MidNarrowCell>
              <MidNarrowCell />

              {statTypeDescriptions.map((statType) => {
                return editablePriceCell(editablePriceCellParamsFor(ConsultationType.InternalMedicine, statType, 0))
              })}
            </Row>
          )}
        </tbody>
      </Table>

      {!isReadOnly && (
        <AddonSettings
          group={props.group}
          isDefaultPriceGroup={isDefaultPriceGroup}
          setDefaultPriceGroup={setDefaultPriceGroup}
          ultrasoundPrices={ultrasoundPrices}
          catscanPrices={catscanPrices}
          mriPrices={mriPrices}
        />
      )}
    </div>
  )
}
